import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyInterventionsActivitiesStyle = makeStyles()(theme => ({
  root: {
    marginTop: 12,
    marginBottom: 70,
    minHeight: 620,
  },
  step: {
    "& .MuiStepLabel-label": {
      marginTop: 6,
    },
  },
  alternativeLabel: {
    color: theme.palette.gray.main,
  },
  infoWrapper: {
    marginTop: 16,
  },
}));
