import { Visit } from "@health/queries/types";
import { CircularProgress, Grid } from "@toolkit/ui";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { usePreviousVisitsQuery } from "../../gql";
import { CareEngagement } from "./CareEngagement";
import { PreviousVisits } from "./PreviousVisits";

export const Visits = () => {
  const { token } = useProfileContext();

  const { data, loading } = usePreviousVisitsQuery({
    context: getApolloContextFormToken(token!),
    variables: {
      first: 3,
    },
  });
  const visits = data?.visits?.edges?.map(item => item?.node) as Visit[];

  return (
    <Grid item container spacing={1} xs={12}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {!!visits?.length && (
            <Grid item xs={12} sm={12} md={6}>
              <PreviousVisits visits={visits!} />
            </Grid>
          )}
          {/* {!!visitPrescriptions?.length && (*/}
          {/*  <Grid item xs={12} sm={12} md={6}>*/}
          {/*    <ActiveMedications visitPrescriptions={visitPrescriptions!} />*/}
          {/*  </Grid>*/}
          {/* )}*/}
          <Grid item xs={12} sm={12} md={6}>
            <CareEngagement />
          </Grid>
        </>
      )}
    </Grid>
  );
};
