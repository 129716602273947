import { CustomTable } from "@toolkit/ui";
import React from "react";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { useHealthDocumentsColumns } from "./useHealthDocumentsColumns";

export const HealthDocuments = () => {
  const { loading, patientHealthDocuments } = useProfileContext();

  return (
    <CustomTable
      data={patientHealthDocuments || []}
      columns={useHealthDocumentsColumns()}
      isLoading={loading}
      scrollable
      hasFooter={false}
    />
  );
};
