import { PatientProfileTabContainer } from "@/shared/modules/patient/components/PatientProfileTabContainer";
import { HealthDocuments } from "@/shared/modules/patient/components/HealthDocuments/HealthDocuments/HealthDocuments";

export const HealthDocumentsTab = () => {
  return (
    <PatientProfileTabContainer
      isContainer
      isRoot
      sections={[
        {
          content: <HealthDocuments />,
        },
      ]}
    />
  );
};
