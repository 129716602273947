import { VisitStatus } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, Tooltip, Typography, useToasts } from "@toolkit/ui";
import { useVisitStatusUpdateMutation } from "pages/e-visit/gql";
import { VisitsDocument } from "pages/VisitHistory/gql";
import { type FC, useState } from "react";

type CompleteVisitButtonProps = {
  visitId: string;
  visitStatus: VisitStatus;
  onToggle: () => void;
};

export const CompleteVisitButton: FC<CompleteVisitButtonProps> = props => {
  const { visitId, visitStatus, onToggle } = props;

  const [helperText, setHelperText] = useState<string | null>(null);

  const { t } = useTranslation("provider");
  const { addToast } = useToasts();

  const isCompleted = visitStatus !== VisitStatus.Incompleted;

  const [visitStatusUpdateMutation] = useVisitStatusUpdateMutation({
    onCompleted: response => {
      const errors = response?.doctorVisitComplete?.visitErrors;
      if (errors?.length === 0) {
        setHelperText(null);
        addToast("Visit is completed", {
          appearance: "success",
          autoDismiss: false,
        });
        onToggle();
      } else {
        setHelperText(formatMessageErrors(errors));
        addToast(formatMessageErrors(errors), {
          appearance: "error",
          autoDismiss: false,
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      addToast(formatGraphQLError(graphQLErrors), {
        appearance: "error",
        autoDismiss: false,
      });
      setHelperText(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: VisitsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const handleCompleteVisit = () => {
    visitStatusUpdateMutation({
      variables: {
        visitStatusUpdateId: visitId!,
      },
    });
  };

  return (
    <>
      <Tooltip placement='top' title={isCompleted ? t("The visit status must be incomplete.") : ""}>
        <span>
          <Button color='warning' disabled={isCompleted} onClick={handleCompleteVisit}>
            {t("Complete visit")}
          </Button>
        </span>
      </Tooltip>

      {helperText && (
        <Typography sx={{ px: 2 }} color='red'>
          {helperText}
        </Typography>
      )}
    </>
  );
};
