import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import QRCode from "qrcode.react";
import React, { useEffect } from "react";
import { useDispenseModelStyles } from "./DispenseModel.styles";
import IoHealthLogo from "assets/svg/ioHealth.svg";

type OrderToPrintProps = {
  refForPrint: React.RefObject<HTMLDivElement>;
  number: string;
  toPrint: Date | undefined;
  awb?: string;
  setPrintReady: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

const OrderQR = ({ refForPrint, toPrint, setPrintReady, number, awb }: OrderToPrintProps) => {
  const { classes, theme } = useDispenseModelStyles();
  const { t, i18n } = useTranslation("provider");

  useEffect(() => {
    if (toPrint) {
      setPrintReady(new Date());
    }
  }, [toPrint]);

  return (
    <Box display={window.matchMedia("print") ? "none" : "flex"}>
      <div dir={i18n.dir(i18n.language)} ref={refForPrint}>
        <Box height='100vh' bgcolor='transparent' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Typography className={classes.id}>
            {t("Order ID")} - #{number}
          </Typography>
          <Box sx={{ position: "relative", margin: "0px" }}>
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img src={IoHealthLogo} width={60} height={60} />
            </Box>

            <Box
              sx={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QRCode
                id={String(number)}
                value={String(number)}
                bgColor={theme.palette.common.white}
                size={350}
                level={"H"}
                includeMargin={true}
              />
            </Box>
          </Box>
          {awb && (
            <Box className={classes.box}>
              <Typography dir={i18n.dir(i18n.language)} className={classes.id} gutterBottom>
                {t("AWB Number")} - {awb}
              </Typography>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default OrderQR;

OrderQR.defaultProps = {
  awb: false,
};
