import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FlowiseIntegrationChatSortingField, FlowrseIntegrationSortDirection } from "@health/queries/types";
import { FilterGrid, PageWrapper, TableGrid } from "@toolkit/ui";
import { SyncButton } from "../../../AIAgents/components";
import { GridProvider } from "../../../../shared/components";
import { useChatFlowsQuery } from "../../gql/queries";
import { useAgentsListTableColumns } from "./useAgentsListTableColumns";
export const AgentsList = ({ isAdmin, handleEditClick }) => {
    return (_jsx(GridProvider, { gridName: 'marketplace-brands', query: useChatFlowsQuery, columns: useAgentsListTableColumns({ isAdmin, handleEditClick }), variables: {
            sortBy: {
                field: FlowiseIntegrationChatSortingField.Updated,
                direction: FlowrseIntegrationSortDirection.Desc,
            },
        }, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: isAdmin ? _jsx(SyncButton, {}) : _jsx(_Fragment, {}), children: _jsx(TableGrid, {}) }) }));
};
