export enum JourneyDurationPeriod {
  Annual = "Annual",
  Quarterly = "Quarterly",
}

export enum JourneyActivityScope {
  AllActivities = "AllActivities",
  MyActivities = "MyActivities",
}

export enum JourneyActivityFilter {
  ShowAll = "ShowAll",
  HideMedicalMessages = "HideMedicalMessages",
}

export enum ProfileContentTab {
  Overview = "0",
  Journey = "1",
  Assessments = "2",
  Readings = "3",
  Documents = "4",
  Tasks = "5",
}
