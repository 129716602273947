import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { healthDocumentTypeOptionsMap } from "@health/enum-options";
import { PatientHealthDocument } from "@health/queries/types";
import { HealthDocumentDownload } from "../HealthDocumentDownload/HealthDocumentDownload";
import moment from "moment";

export const useHealthDocumentsColumns = (): CustomTableColumnProps<PatientHealthDocument>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => healthDocumentTypeOptionsMap[type]?.label || "-",
      },
      {
        key: "date",
        header: t("Date"),
        accessor: ({ resultDate }) => moment(resultDate).format("DD MMM YYYY hh:mm a"),
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description }) => <TruncateTypography text={description || "-"} />,
      },
      {
        key: "download",
        header: t("Download"),
        accessor: ({ fileLink }) => <HealthDocumentDownload fileLink={fileLink} />,
      },
    ];
  }, [t]);
};
