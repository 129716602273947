import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { ButtonProps } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { DefaultPatientProfileButton } from "../components/DefaultPatientProfileButton";
import { PatientProfileDrawer } from "../components/PatientProfileDrawer";
import { ProfileContextProvider } from "../context/ProfileContextProvider";
import { ProfileContentTab } from "@/shared/modules/patient/types";

type PatientProfileProps = {
  patientId: string | undefined;
  patientName?: {
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
  };
  renderModalButton?: (props: ButtonProps) => React.ReactNode;
  defaultSelectedTab?: ProfileContentTab;
  defaultProgramId?: string;
  tokenId?: string;
  DrawerToggleProps?: {
    isOpen: boolean;
    onClose?: () => void;
  };
};

export const PatientProfile: FC<PatientProfileProps> = props => {
  const {
    patientId,
    patientName,
    renderModalButton,
    defaultSelectedTab = ProfileContentTab.Overview,
    tokenId,
    DrawerToggleProps,
    defaultProgramId,
  } = props;

  const { t } = useTranslation("provider");

  const [open, setOpen] = useState<boolean>(false);

  const handleToggleDrawer = isOpen => event => {
    event.stopPropagation();
    setOpen(isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {renderModalButton ? (
        renderModalButton({ onClick: handleToggleDrawer(true) })
      ) : (
        <DefaultPatientProfileButton onClick={handleToggleDrawer(true)} patientName={formatUserNameFull(patientName) || t("Patient")} />
      )}

      <ProfileContextProvider
        patientId={patientId}
        isOpen={DrawerToggleProps?.isOpen ?? open}
        // eslint-disable-next-line react/jsx-handler-names
        onClose={DrawerToggleProps?.onClose ?? handleClose}
        defaultSelectedTab={defaultSelectedTab || ProfileContentTab.Overview}
        defaultProgramId={defaultProgramId}
        tokenId={tokenId}
      >
        <PatientProfileDrawer />
      </ProfileContextProvider>
    </>
  );
};
