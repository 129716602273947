import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Menu, MenuItem, Tooltip, Typography } from "@toolkit/ui";
import { FC, useState } from "react";
import { useProfileContext } from "../../../context/ProfileContext";
import { useStyles } from "./PatientAddActivity.styles";
import { PatientActivityType } from "./PatientAddActivity.types";
import { GuidedCareJourneyInterventionsActivitiesModalApi } from "@/shared/modules/patient/modals/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivitiesModal";

type PatientAddActivityButtonProps = {
  onSelect: (type: PatientActivityType) => void;
};

export const PatientAddActivityButton: FC<PatientAddActivityButtonProps> = ({ onSelect }) => {
  const { patient } = useProfileContext();

  const { t } = useTranslation("provider");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (type: PatientActivityType) => {
    return () => {
      setAnchorEl(null);
      onSelect(type);
    };
  };
  const isOpen = !!anchorEl;
  const { classes, theme } = useStyles({ isOpen });
  const memberId = patient?.activeHealthProgramMembers?.find(member => member?.isDefault || !member?.isDefault)?.id!;

  const onMenuItemInterventionClick = () => {
    GuidedCareJourneyInterventionsActivitiesModalApi.open({ isComingFromAddPersonalizedItem: true });
  };

  return (
    <Box>
      <Tooltip title={!memberId ? t("There is no membership") : ""} placement='top'>
        <span>
          <Button
            id='patient-add-activity-menu-button'
            aria-controls={isOpen ? "patient-add-activity-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={isOpen ? "true" : undefined}
            variant='contained'
            disableElevation
            onClick={handleClick}
            className={classes.btn}
            endIcon={
              <Box className={classes.expandIcon}>
                <CustomIcon icon='expandArrow' viewBox='0 -3 20 20' color='inherit' />
              </Box>
            }
            disabled={!memberId}
          >
            {t("Add Activity")}
          </Button>
        </span>
      </Tooltip>

      <Menu
        sx={{
          marginTop: "8px !important",
          borderRadius: "10px !important",
          // background: "#25287F",
        }}
        id='patient-add-activity-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <MenuItem onClick={handleSelect(PatientActivityType.Visit)}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Visit")}</Typography>
        </MenuItem>

        <MenuItem onClick={handleSelect(PatientActivityType.Service)}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Service")}</Typography>
        </MenuItem>

        <MenuItem onClick={handleSelect(PatientActivityType.MedicalForms)}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Medical Form")}</Typography>
        </MenuItem>

        <MenuItem onClick={onMenuItemInterventionClick}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Intervention")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
