import { GuidedCareJourneyItem } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, IconButton, RescheduleIcon, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { AppointmentReschedule } from "pages/appointments";
import { useMemo } from "react";
import { Note } from "./Note";
import { getActivityDisplayName, getStatusColor } from "./utils";

export type UpcomingPatientCareActivitiesColumnType = CustomTableColumnProps<GuidedCareJourneyItem>;
export type CareEngagementColumnsTypes = UpcomingPatientCareActivitiesColumnType[];

export const useUpcomingPatientCareActivitiesColumns = ({ upcoming = false }): CareEngagementColumnsTypes => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  return useMemo(() => {
    return [
      {
        key: "Type",
        header: t("Type"),
        accessor: ({ guidedCareJourneyItemType }) => (
          <Typography textTransform='capitalize'>{String(guidedCareJourneyItemType).replaceAll("_", " ").toLowerCase()}</Typography>
        ),
        type: "string",
      },
      {
        key: "name",
        header: t("Name"),
        accessor: item => getActivityDisplayName(item) || "-",
        type: "string",
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ medicalForm }) => <TruncateTypography text={medicalForm?.description!} />,
        type: "string",
        isHidden: upcoming,
      },
      {
        key: "date",
        header: t("Date"),
        accessor: ({ dueDate }) => <Typography noWrap>{moment(dueDate).format("DD MMM YYYY")}</Typography>,
        type: "string",
      },
      {
        key: "Status",
        header: t("Status"),
        accessor: ({ guidedCareJourneyItemStatus }) => (
          <Typography
            textTransform='capitalize'
            textAlign={"center"}
            sx={{
              color: theme.palette.common.white,
              borderRadius: "25px",
              paddingInline: "10px",
              background: getStatusColor(guidedCareJourneyItemStatus!, theme.palette),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
              height: "30px",
            }}
          >
            {String(guidedCareJourneyItemStatus).replaceAll("_", " ").toLowerCase()}
          </Typography>
        ),

        type: "string",
      },
      {
        key: "Note",
        header: t("Note"),
        accessor: ({ id }) => <Note id={id!} />,
        type: "string",
      },
      {
        key: "Reschedule",
        header: t("Reschedule"),
        accessor: ({ appointment }) => (
          <AppointmentReschedule
            status={appointment?.status! || "-"}
            id={appointment?.id!}
            doctorId={appointment?.doctor?.id}
            patient={appointment?.consumer}
            paymentInfo={appointment?.paymentInfo}
            paymentStatus={appointment?.paymentStatus}
            buttonEl={
              <IconButton disabled={!appointment}>
                <RescheduleIcon />
              </IconButton>
            }
          />
        ),
        type: "string",
      },
    ];
  }, []);
};
