import { getGuidedCareProgramsAutocompleteFilter } from "@health/autocompletes";
import { GuidedCareJourney, GuidedCareJourneySortField, OrderDirection } from "@health/queries/types";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps } from "@toolkit/ui";
import { AdherenceProgress } from "pages/GuidedCarePatients/Components/AdherenceProgress/AdherenceProgress";
import { GapsCircle } from "pages/GuidedCarePatients/Components/GapsCircle/GapsCircle";
import { useMemo } from "react";
import { ChatDialog } from "shared/components/Chat/ChatDialog";
import { PatientProfile } from "shared/modules/patient";
import { PatientCareJourney } from "./PatientCareJourney";
import { ProfileContentTab } from "@/shared/modules/patient/types";

export const useGetGuidedPatientsColumns = (): CustomTableColumnProps<GuidedCareJourney>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "photo",
        header: "",
        accessor: ({ patientMedicalProfile }) => <Avatar src={getMediaLink(patientMedicalProfile?.patient?.user?.photo!)} />,
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ patientMedicalProfile, guidedCareHealthProgram }) => (
          <PatientProfile
            patientId={patientMedicalProfile?.patient?.id}
            defaultProgramId={guidedCareHealthProgram?.id}
            patientName={{
              firstName: patientMedicalProfile?.patient?.firstName,
              lastName: patientMedicalProfile?.patient?.lastName,
            }}
          />
        ),
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "healthProgram",
        header: t("Health Program"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.name || "--",
        filter: getGuidedCareProgramsAutocompleteFilter({ name: "healthProgramId" }),
      },
      {
        key: "careTeam",
        header: t("Care Team"),
        accessor: ({ providerGuidedCareHealthProgramTeam }) => providerGuidedCareHealthProgramTeam?.name || "--",
      },
      {
        key: "activities",
        header: t("Activities"),
        accessor: ({ activities }) => activities || "--",
      },
      {
        key: "adherence",
        header: t("Adherence"),
        accessor: ({ adherence }) => <AdherenceProgress value={adherence!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: GuidedCareJourneySortField.Adherence,
      },
      {
        key: "gaps",
        header: t("Gaps"),
        accessor: ({ gaps }) => <GapsCircle value={gaps!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: GuidedCareJourneySortField.Gaps,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ patientMedicalProfile, guidedCareHealthProgram }) => (
          <Box display='flex' flexDirection='row' gap={2}>
            <Box display='flex' alignItems='start'>
              <PatientCareJourney
                patient={patientMedicalProfile?.patient!}
                defaultProgramId={guidedCareHealthProgram?.id}
                selectedTab={ProfileContentTab.Tasks}
              />
            </Box>
            <Box display='flex' alignItems='start'>
              <ChatDialog userId={patientMedicalProfile?.patient?.user?.id!} />
            </Box>
          </Box>
        ),
      },
    ];
  }, [t]);
};
