import { CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { GuidedCareJourneyIntervention } from "@health/queries/types";
import {
  GuidedCareJourneyInterventionsActivities,
  IGuidedCareJourneyInterventionsActivitiesEvent,
} from "../../components/GuidedCareJourneyInterventions/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivities";
import { useGuidedCareJourneyInterventionsActivitiesModalStyle } from "./useGuidedCareJourneyInterventionsActivitiesModalStyle";

type IGuidedCareJourneyInterventionsActivitiesModalData = {
  eligibleInterventions?: GuidedCareJourneyIntervention[];
  isComingFromAddPersonalizedItem?: boolean;
};

type IOpen = (data: IGuidedCareJourneyInterventionsActivitiesModalData) => void;
type IClose = () => void;

export const GuidedCareJourneyInterventionsActivitiesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareJourneyInterventionsActivitiesModalProps = {
  isLoading: boolean;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesModal: FC<GuidedCareJourneyInterventionsActivitiesModalProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareJourneyInterventionsActivitiesModalData>();

  const { classes } = useGuidedCareJourneyInterventionsActivitiesModalStyle();

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareJourneyInterventionsActivitiesModalApi.open = open;
    GuidedCareJourneyInterventionsActivitiesModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        hidden: true,
      }}
      DialogContentProps={{
        classes: {
          root: classes.dialogContent,
        },
      }}
    >
      <GuidedCareJourneyInterventionsActivities
        isLoading={isLoading}
        isComingFromAddPersonalizedItem={data?.isComingFromAddPersonalizedItem}
        eligibleInterventions={data?.eligibleInterventions!}
        onDialogClose={onDialogClose}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
